import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import ImageGallery from "react-image-gallery";
import "./image-gallery.css"

import { styled } from '@mui/material/styles';

import './Home.css'
import './About.css'
import './Blog-Template.css'
import './Contact.css'
import './nicepage.css'
import './Post-Template.css'
import './Product-Details-Template.css'
import './Product-List-Template.css'
import './Thank-You-Page-Template.css'
import './intlTelInput.css'

import image1 from '../../assets/image/1055183-31de555a.png'
import fghftgfughj from '../../assets/image/coins.svg'
import fjkregfer from '../../assets/image/goal.svg'
import jknujbnjlk from '../../assets/image/strategy.svg'
import hjerbfgherwf from '../../assets/image/investment.svg'
import regvkewrtnglwe from '../../assets/image/regvkewrtnglwe.svg'
import defaultlogo from '../../assets/image/default-logo.png'
import rytyy from '../../assets/image/rytyy.jpg'
import image2 from '../../assets/image/7350737-fd9408b8.png'
import untitled1 from '../../assets/image/Untitled-1.jpg'



// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    // height:'100vh',
    // overflow:'auto'
}));




const images = [
  {
    original: "https://wallpapercave.com/wp/wp5042743.jpg",
    thumbnail: "https://wallpapercave.com/wp/wp5042743.jpg",
  },
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];



export default function Publications(props) {

    const [startcount,setStartCount] = useState(false)
    
    return (
        <RootStyle>

        <Fade>
          <div style={{display:'flex', width:'100%', height:'400px', justifyContent:'center', backgroundColor:'#ccc' }}>
            <ImageGallery items={images}
              thumbnailWidth          
              showPlayButton={false}
              showBullets
              showThumbnails={false}
              showFullscreenButton={false}
              slideInterval={5000}
              autoPlay
            />
          </div>
        </Fade>

<div className="u-body u-xl-mode" >





    <section className="u-align-center u-clearfix u-container-align-center u-section-2" id="carousel_486e" >
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1 ">
      <Fade >
        <h2 style={{marginTop:'-70px'}} className="fade-in u-align-center u-text u-text-1 " data-animation-name="customAnimationIn" data-animation-duration="1500">Get Great Work for Your Budget</h2>
      </Fade>
        <div className="u-expanded-width u-list u-list-1">
          <div className="u-repeater u-repeater-1">
            <Fade up>
            <div  className="slide-from-down u-align-left u-container-align-center-xl u-container-style u-image u-image-round u-list-item u-radius-20 u-repeater-item u-shading u-image-1" data-image-width="1014" data-image-height="700" data-animation-direction="Up" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span className="u-align-center u-icon u-icon-rectangle u-opacity u-opacity-70 u-text-white u-icon-1"><img src={fghftgfughj} width='80%' /></span>
                <h4 className="u-align-center u-text u-text-3">Consulting</h4>
                <p className="u-align-center u-text u-text-4">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              </div>
            </div>
            </Fade>
            <Fade down>
            <div className="slide-from-up u-align-left u-container-align-center u-container-style u-image u-image-round u-list-item u-radius-20 u-repeater-item u-shading u-video-cover u-image-2" data-image-width="626" data-image-height="417" data-animation-direction="Up" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span className="u-align-center u-icon u-icon-rectangle u-opacity u-opacity-70 u-icon-2"><img src={jknujbnjlk}  width='80%' /></span>
                <h4 className="u-align-center u-text u-text-5">Strategy</h4>
                <p className="u-align-center u-text u-text-6">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              </div>
            </div>
            </Fade>
            <Fade up>
            <div className="slide-from-down u-align-left u-container-align-center u-container-style u-image u-image-round u-list-item u-radius-20 u-repeater-item u-shading u-video-cover u-image-3" data-image-width="1200" data-image-height="1043" data-animation-direction="Up" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3"><span className="u-align-center u-icon u-icon-rectangle u-opacity u-opacity-70 u-icon-3"><img src={fjkregfer} width='80%' /></span>
                <h4 className="u-align-center u-text u-text-7">Mission</h4>
                <p className="u-align-center u-text u-text-8">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              </div>
            </div>
            </Fade>
            <Fade down>
            <div className="slide-from-up u-align-left u-container-align-center u-container-style u-image u-image-round u-list-item u-radius-20 u-repeater-item u-shading u-video-cover u-image-4" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4"><span className="u-align-center u-icon u-icon-rectangle u-opacity u-opacity-70 u-text-white u-icon-4"><img src={hjerbfgherwf}  width='100%' /></span>
                <h4 className="u-align-center u-text u-text-9">Investment</h4>
                <p className="u-align-center u-text u-text-10">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              </div>
            </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>

    <section className="u-align-left u-clearfix u-white u-section-3" id="carousel_06d6">
      <div className="u-clearfix u-sheet u-valign-bottom u-sheet-1">
        <Fade>
        <h2 className="fade-in u-text u-text-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250"> What Is a Consulting Firm &amp; What Does</h2>
        </Fade>
        <div className="data-layout-selected u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
            <Fade left>
              <div className="u-align-left u-container-align-left u-container-style u-layout-cell u-shape-rectangle u-size-20-lg u-size-20-xl u-size-30-md u-size-30-sm u-size-30-xs u-layout-cell-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">
                <div className="u-container-layout u-valign-top u-container-layout-1">
                  <p className="slide-from-left u-align-left u-spacing-20 u-text u-text-2">
                    <span style={{fontWeight: '700'}}>01.</span> Agile <br />
                    <span style={{fontWeight: '700'}}>02.</span> Cost Transformation<br />
                    <span style={{fontWeight: '700'}}>03.</span> Learning &amp; Development<br />
                    <span style={{fontWeight: '700'}}>04.</span> Mergers and Acquisitions <br />
                    <span style={{fontWeight: '700'}}>05.</span> People and Organization<br />
                    <span style={{fontWeight: '700'}}>06.</span> Private Equity<br />
                  </p>
                  <a href="#https://drinkupbottle.com/" className="fade-in u-active-black u-align-center-xs u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xl u-border-active-white u-border-hover-white u-border-none u-btn u-btn-round u-button-style u-hover-black u-palette-1-base u-radius-5 u-btn-2">Learn More</a>
                </div>
              </div>
                </Fade>
                <Fade right>
              <div className="u-align-left u-container-align-left u-container-style u-layout-cell u-shape-rectangle u-size-17-lg u-size-17-xl u-size-30-md u-size-30-sm u-size-30-xs u-layout-cell-2" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">
                <div className="u-container-layout u-valign-top u-container-layout-2">
                  <p className="slide-from-right u-align-left u-spacing-20 u-text u-text-4">
                    <span style={{fontWeight: '700'}}>07.</span> Mergers and Acquisitions<br />
                    <span style={{fontWeight: '700'}}>08.</span> Private Equity <br />
                    <span style={{fontWeight: '700'}}>09.</span> Operations <br />
                    <span style={{fontWeight: '700'}}>10.</span> Sales and Marketing <br />
                    <span style={{fontWeight: '700'}}>11.</span> Sustainability &amp; Responsibility<span style={{fontWeight: '700'}}>
                      <br/>12.
                    </span> Transformation
                  </p>
                </div>
              </div>
                </Fade>
                <Fade right>
              <div className="slide-from-right u-container-style u-image u-layout-cell u-size-23-lg u-size-23-xl u-size-60-md u-size-60-sm u-size-60-xs u-image-1" data-image-width="751" data-image-height="1080" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                <div className="u-container-layout u-container-layout-3"></div>
              </div>
                </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-align-center u-clearfix u-container-align-center u-palette-1-base u-section-4" id="carousel_13aa">
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
        <Fade>
        <h2 className="u-align-center u-text u-text-default u-text-1" data-animation-name="customAnimationIn" data-animation-duration="1500">Get Right Solution&nbsp;<br/>for your Business
        </h2>
        <p className="u-align-center u-text u-text-2" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
        </Fade>
        <div className="data-layout-selected u-clearfix u-layout-custom-sm u-layout-custom-xs u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
                <Fade left>
              <div className="u-align-center u-container-align-center-xs u-container-align-right-lg u-container-align-right-md u-container-align-right-sm u-container-align-right-xl u-container-style u-layout-cell u-left-cell u-size-30-lg u-size-30-md u-size-30-sm u-size-30-xl u-size-60-xs u-layout-cell-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                  <a href="#https://drinkupbottle.com/" className="u-active-white u-align-center-xs u-align-right-lg u-align-right-md u-align-right-sm u-align-right-xl u-border-2 u-border-active-white u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-hover-white u-none u-radius-5 u-text-active-palette-1-base u-text-hover-palette-1-base u-btn-1">Learn More</a>
                </div>
              </div>
                </Fade>
                <Fade right>
              <div className="u-align-center u-container-align-center-xs u-container-align-left-lg u-container-align-left-md u-container-align-left-sm u-container-align-left-xl u-container-style u-layout-cell u-right-cell u-size-30-lg u-size-30-md u-size-30-sm u-size-30-xl u-size-60-xs u-layout-cell-2" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750">
                <div className="u-container-layout u-valign-middle u-container-layout-2">
                  <a href="#https://drinkupbottle.com/" className="u-active-palette-1-light-1 u-align-center-xs u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xl u-border-2 u-border-active-palette-1-light-2 u-border-hover-palette-1-light-1 u-border-white u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-radius-5 u-text-active-white u-text-hover-white u-text-palette-1-base u-white u-btn-2">Learn More</a>
                </div>
              </div>
                </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-white u-section-5" id="carousel_f528">
        <Fade right>

      <div className="u-expanded-width u-palette-1-base u-shape u-shape-rectangle u-shape-1"></div>
      <img className="u-image u-image-round u-radius-20 u-image-1" src={rytyy}  data-image-width="1344" data-image-height="896" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500" />
        </Fade>
      <Fade left>

      <div className="u-align-left u-container-align-left u-container-style u-group u-radius-20 u-shape-round u-white u-group-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
        <div className="u-container-layout u-valign-middle u-container-layout-1">
          <h3 className="u-align-left u-text u-text-1"> Organisation consulting</h3>
          <p className="u-align-left u-text u-text-default u-text-2"> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <a href="#https://drinkupbottle.com/" className="u-active-black u-align-center-xs u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xl u-border-active-white u-border-hover-white u-border-none u-btn u-btn-round u-button-style u-hover-black u-palette-1-base u-radius-5 u-btn-1">Learn More</a>
        </div>
      </div>
      </Fade>
    </section>
    <section className="u-align-left u-clearfix u-section-6" id="carousel_0b16">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-row">
                <Fade up>

              <div className="u-align-center u-container-style u-image u-image-round u-layout-cell u-radius-20 u-right-cell u-size-29 u-image-1" data-image-width="1272" data-image-height="1035" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                <div className="u-container-layout u-container-layout-1"></div>
              </div>
                </Fade>
              <Fade down>

              <div className="u-align-center u-container-align-center u-container-style u-layout-cell u-left-cell u-shape-rectangle u-size-31 u-layout-cell-2" data-animation-name="flipIn" data-animation-duration="1500" data-animation-direction="X" data-animation-delay="500">
                <div className="u-container-layout u-valign-middle u-container-layout-2">
                  <a href="https://nicepage.com/html-templates" className="u-active-palette-1-base u-align-center u-border-none u-btn u-btn-round u-button-style u-custom-font u-font-raleway u-hover-black u-palette-1-base u-radius-5 u-text-body-alt-color u-btn-1">contacts</a>
                  <h2 className="u-align-center u-text u-text-1"> Together for a business lifestyle</h2>
                  <p className="u-align-center u-text u-text-2"> We are here to meet any business need and to promote your company online!</p>
                  <h5 className="u-align-center u-custom-font u-font-montserrat u-text u-text-3">
                    <span style={{fontWeight: '700'}}>Phone</span>: 1 (232) 252 55 22 <br />
                    <span style={{fontWeight: '700'}}>Location</span>: 75  Street Sample, WI 63025 <br />
                    <span style={{fontWeight: '700'}}>Mail</span>: template@sample.com
                  </h5>
                </div>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-align-center u-clearfix u-container-align-center u-palette-1-base u-valign-bottom-lg u-valign-bottom-xl u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-section-7" id="carousel_ec55" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-align-left-lg u-container-align-left-xl u-container-style u-layout-cell u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-layout-cell-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                    <Fade left>

                  <h2 className="u-align-left u-text u-text-1" data-animation-name="customAnimationIn" data-animation-duration="1500"> Strategies and Solutions</h2>
                  <div className="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                      <div className="u-container-align-left u-container-style u-list-item u-repeater-item u-list-item-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                        <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                          <h3 className="u-align-left u-custom-font u-font-montserrat u-text u-text-default u-text-2">01.&nbsp;​​We sculpt ideas</h3>
                          <p className="u-align-left u-text u-text-3"> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                      </div>
                      <div className="u-container-align-left u-container-style u-list-item u-repeater-item u-list-item-2" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                        <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
                          <h3 className="u-align-left u-custom-font u-font-montserrat u-text u-text-default u-text-4">02.&nbsp;​We bring concepts to life</h3>
                          <p className="u-align-left u-text u-text-5"> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                      </div>
                      <div className="u-container-align-left u-container-style u-list-item u-repeater-item u-list-item-3" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
                        <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4">
                          <h3 className="u-align-left u-custom-font u-font-montserrat u-text u-text-default u-text-6">03.&nbsp;​We create winning realities</h3>
                          <p className="u-align-left u-text u-text-7"> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="u-align-left u-text u-text-body-alt-color u-text-default u-text-8">Im<span style={{fontStyle: 'italic'}}></span>age from <a href="https://freepik.com" className="u-border-1 u-border-active-palette-2-light-2 u-border-hover-palette-2-light-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-btn u-button-link u-button-style u-none u-text-body-alt-color u-btn-1" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">Freepik</a>
                  </p>
                  </Fade>
                </div>
              </div>
              <Fade right>

              <div className="u-container-align-center u-container-style u-image u-image-round u-layout-cell u-radius-20 u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-image-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750" data-image-width="1200" data-image-height="800">
                <div className="u-container-layout u-container-layout-5"></div>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-align-left u-clearfix u-container-align-center u-white u-section-8" id="carousel_840a">
      <Fade down>

      <div className="u-expanded-width u-palette-1-base u-shape u-shape-rectangle u-shape-1"></div>
      <h2 className="u-align-center u-text u-text-body-alt-color u-text-default-lg u-text-default-md u-text-default-xl u-text-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250"> What clients say<br/>
      </h2>
      </Fade>
      <div className="u-list u-list-1">
        <div className="u-repeater u-repeater-1">
            <Fade up >

          <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span className="u-file-icon u-icon u-text-palette-1-base u-icon-1"><img src={image2}  /></span>
              <p className="u-text u-text-default u-text-grey-50 u-text-2">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              <div  className="u-image u-image-circle u-image-1" data-image-width="501" data-image-height="501"></div>
              <h5 className="u-text u-text-default u-text-palette-1-base u-text-3"> Sasha Grey</h5>
              <p className="u-text u-text-default u-text-4">Business Owner</p>
            </div>
          </div>
            </Fade>
            <Fade down>

          <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-2" data-animation-direction="Up" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span className="u-file-icon u-icon u-text-palette-1-base u-icon-2"><img src={image2} /></span>
              <p className="u-text u-text-default u-text-grey-50 u-text-5">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              <div  className="u-image u-image-circle u-image-2" data-image-width="206" data-image-height="206"></div>
              <h5 className="u-text u-text-default u-text-palette-1-base u-text-6">Nat Reynolds</h5>
              <p className="u-text u-text-default u-text-7">Chief Accountant</p>
            </div>
          </div>
            </Fade>
            <Fade up>

          <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3" data-animation-direction="Up" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3"><span className="u-file-icon u-icon u-text-palette-1-base u-icon-3"><img src={image2} /></span>
              <p className="u-text u-text-default u-text-grey-50 u-text-8">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
              <div  className="u-image u-image-circle u-image-3" data-image-width="626" data-image-height="417"></div>
              <h5 className="u-text u-text-default u-text-palette-1-base u-text-9">Bob Roberts</h5>
              <p className="u-text u-text-default u-text-10">Sales Manager</p>
            </div>
          </div>
            </Fade>
        </div>
      </div>
    </section>
    <section className="u-align-center u-clearfix u-section-9" id="carousel_5987">
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
        <div className="u-list u-list-1">
          <div className="u-repeater u-repeater-1">
            <Fade onReveal={()=>{setStartCount(true)}}>
                {startcount &&

            <div className="u-align-center u-container-align-left u-container-style u-custom-item u-list-item u-palette-1-base u-radius-50 u-repeater-item u-shape-round u-list-item-1" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">
              <div className="u-container-layout u-similar-container u-container-layout-1">
                <h3 className="u-align-left u-custom-font u-text u-text-font u-text-1" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">
                    <CountUp end={50}  />%
                    </h3>
                <p className="u-align-left u-custom-item u-text u-text-2" data-animation-name="customAnimationIn" data-animation-duration="1000">Sample text. Click to select the text box.</p>
              </div>
            </div>
                }
            </Fade>
            <Fade>
            {startcount &&

            <div className="u-align-center u-container-align-left u-container-style u-custom-item u-list-item u-palette-1-dark-1 u-radius-50 u-repeater-item u-shape-round u-list-item-2" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500">
              <div className="u-container-layout u-similar-container u-container-layout-2">
                <h3 className="u-align-left u-custom-font u-text u-text-font u-text-3" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"><CountUp end={75} />%</h3>
                <p className="u-align-left u-custom-item u-text u-text-4" data-animation-name="customAnimationIn" data-animation-duration="1000">Click again or double.</p>
              </div>
            </div>
            }
            </Fade>
            <Fade>
                {startcount &&

            <div className="u-align-center u-container-align-left u-container-style u-custom-item u-grey-5 u-list-item u-radius-50 u-repeater-item u-shape-round u-list-item-3" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="750">
              <div className="u-container-layout u-similar-container u-container-layout-3">
                <h3 className="u-align-left u-custom-font u-text u-text-font u-text-5" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"><CountUp end={97} />%</h3>
                <p className="u-align-left u-custom-item u-text u-text-6" data-animation-name="customAnimationIn" data-animation-duration="1000">Click again or double click to start editing the text.</p>
              </div>
            </div>
            }
            </Fade>
          </div>
        </div>
        <Fade left>

        <h5 className="u-align-left u-custom-font u-font-montserrat u-text u-text-7" data-animation-name="customAnimationIn" data-animation-duration="1750" data-animation-delay="750"> Together we can envision, inspire and transform your business</h5>
        </Fade>
      </div>
    </section>
    <section className="u-clearfix u-image u-section-10" id="carousel_acb2" data-image-width="1980" data-image-height="1200">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="data-layout-selected u-clearfix u-expanded-width-sm u-expanded-width-xs u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
                <Fade left>

              <div className="u-container-style u-layout-cell u-left-cell u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-layout-cell-1">
                <div className="u-container-layout u-valign-bottom u-container-layout-1">
                  <img className="u-expanded-width u-image u-image-1" src={untitled1} data-image-width="1001" data-image-height="1080" data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="500" />
                </div>
              </div>
                </Fade>
                <Fade right>

              <div className="u-container-align-center u-container-style u-layout-cell u-right-cell u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-white u-layout-cell-2" data-animation-name="customAnimationIn" data-animation-duration="1000">
                <div className="u-container-layout u-valign-middle u-container-layout-2">
                  <h6 className="u-align-center u-custom-font u-font-montserrat u-text u-text-default u-text-1">Contact Us</h6>
                  <div className="u-expanded-width u-form u-form-1">
                    <form action="https://forms.nicepagesrv.com/v2/form/process" className="u-clearfix u-form-spacing-15 u-form-vertical u-inner-form" style={{padding: '0'}} source="email" name="form">
                      <div className="u-form-group u-form-name">
                        <label htmlFor="name-daf4" className="u-label">Name</label>
                        <input type="text" placeholder="Enter your Name" id="name-daf4" name="name" className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle" required="" wfd-id="id1101" />
                      </div>
                      <div className="u-form-email u-form-group">
                        <label htmlFor="email-daf4" className="u-label">Email</label>
                        <input type="email" placeholder="Enter a valid email address" id="email-daf4" name="email" className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle" required="" wfd-id="id1102" />
                      </div>
                      <div className="u-form-group u-form-message">
                        <label htmlFor="message-daf4" className="u-label">Message</label>
                        <textarea placeholder="" rows="4" cols="50" id="message-daf4" name="message" className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle" required=""></textarea>
                      </div>
                      <div className="u-form-agree u-form-group u-form-group-4">
                        <label className="u-block-b30a-41 u-field-label" ></label>
                        <input type="checkbox" id="agree-f183" name="agree" className="u-agree-checkbox u-field-input" required="" wfd-id="id1103" />
                        <label htmlFor="agree-f183" className="u-block-b30a-38 u-field-label" >I accept the <a href="/home">Terms of Service</a>
                        </label>
                      </div>
                      <div className="u-align-left u-form-group u-form-submit">
                        <a href="/home" className="u-active-grey-75 u-border-none u-btn u-btn-round u-btn-submit u-button-style u-hover-grey-75 u-palette-1-base u-radius-5 u-btn-1">Submit</a>
                        <input type="submit" value="submit" className="u-form-control-hidden" wfd-id="id1104" />
                      </div>
                      <div className="u-form-send-message u-form-send-success"> Thank you! Your message has been sent. </div>
                      <div className="u-form-send-error u-form-send-message"> Unable to send your message. Please fix errors then try again. </div>
                      <input type="hidden" value="" name="recaptchaResponse" wfd-id="id1105" />
                      <input type="hidden" name="formServices" value="499e1256-0e35-925b-3fee-ea7b1b060c85" />
                    </form>
                  </div>
                  <p className="u-align-center u-text u-text-default u-text-2">Image from&nbsp;<a href="https://www.freepik.com/photos/woman" className="u-active-none u-border-1 u-border-active-grey-70 u-border-black u-border-hover-grey-70 u-border-no-left u-border-no-right u-border-no-top u-bottom-left-radius-0 u-bottom-right-radius-0 u-btn u-button-link u-button-style u-hover-none u-none u-radius-0 u-text-body-color u-top-left-radius-0 u-top-right-radius-0 u-btn-2" target="_blank" rel='noreferrer'>Freepik</a>
                  </p>
                </div>
              </div>
              </Fade>

            </div>
          </div>
        </div>
      </div>
    </section>
    

    </div>

        </RootStyle>
    )
}