// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import footerLogo from '../../logo.svg'
// import useWindowSize from '../../hooks/useWindowSize'
import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
// import { useLocation } from 'react-router-dom';
import fsLogo from '../../logo.svg'

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '20px 0',
    backgroundColor: '#313131',
    position: 'relative',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '85.7%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px auto',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
        paddingTop: '2px',
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        paddingTop: '32px',
        width: '82.5%',
    }
}));

const CenterXS = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
        textAlign: 'center'
    }
}));

const InnerContainerStyle = styled('div')(({ theme }) => ({
    width: '85.7%',
    margin: 'auto',
    paddingTop: '21px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '30px',
    },
    [theme.breakpoints.up('md')]: {
        paddingTop: '56px',
    }
}));

const FooterTextWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

const MenuItemStyle = styled('div')(({ theme }) => ({
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '12px',
    paddingTop: '12px',
    paddingBottom: '10px',
    [theme.breakpoints.up('sm')]: {        
    },
}));

const MenuItemStyle_lang = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    textAlign: 'center',
    color: 'white',
    padding: '5px',
    border: 'solid 1px white',
    borderRadius: '5px',
    width: '115px',
    marginTop: '30px',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        margin: 'auto',
        marginTop: '0px',
        marginBottom: '20px',
    },
    [theme.breakpoints.up('sm')]: {
        float: 'left',
    },
}));

const MenuItemStyle_Bold = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: 'white',
    paddingBottom: '10px',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: '17px',
    },
}));

const MenuItemStyle_inner = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: 'white',
    paddingBottom: '10px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: '17px',
    },
}));

const MenuItemStyle_end = styled('div')(({ theme }) => ({
    paddingTop: '50px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '31px',
    },
    [theme.breakpoints.up('md')]: {
        paddingTop: '61px',
    }
}));

const BoxWrapper = styled('div')(({ theme }) => ({
    justifyContent: 'space-between',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
}))

export default function Footer() {
    const { width } = 1000
    // const { width } = useWindowSize()
    const {currentUser} = {}
    const { currentProfile } = {}
    // const {currentUser} = useSelector(state => state.auth);
    // const { currentProfile } = useSelector(state => state.auth);

    // const navigate = useNavigate()
    const navigate = (route)=>{
        window.location = route
    }

    // Obter o ano atual
    const currentYear = new Date().getFullYear();

    // const location = useLocation();
    // const isCreateOrderRoute = location.pathname === '/createorder';
    // const isImportOrderRoute = location.pathname === '/importorder';    
    const isCreateOrderRoute = false
    const isImportOrderRoute = false

    return (
        <RootStyle>
            <ContainerStyle>
                <InnerContainerStyle>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={8} md={5}>
                        <CenterXS>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '80px' }}>
                                <Box component="img" src={footerLogo} alt="DocsTree-logo" width="160px" height="50px" />
                            </div>
                            {isCreateOrderRoute && (
                                    <Box component="img" src={fsLogo} alt="familySearch-logo" width="160px" height="50px" />
                                )}
                            {isImportOrderRoute && (
                                <Box component="img" src={fsLogo} alt="familySearch-logo" width="160px" height="50px" />
                            )}                            
                            <MenuItemStyle>Todos os direitos reservados</MenuItemStyle>
                            <MenuItemStyle_lang>Português</MenuItemStyle_lang>
                        </CenterXS>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} >
                            <CenterXS>
                                <MenuItemStyle_Bold>Links</MenuItemStyle_Bold>
                                    <MenuItemStyle_inner onClick={() => { if (currentUser) { navigate('/'); } else { navigate('/login'); } window.scrollTo(0, 0); }}>Home</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/plans'); window.scrollTo(0, 0);}}>Planos</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/advantages'); window.scrollTo(0, 0);}}>Vantagens</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/partners'); window.scrollTo(0, 0);}}>Parceiros</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/about-us')}} >Sobre</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/login'); window.scrollTo(0, 0);}}>Login</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/signup'); window.scrollTo(0, 0);}}>Cadastro</MenuItemStyle_inner>
                                    <MenuItemStyle_inner onClick={()=>{navigate('/faq'); window.scrollTo(0, 0);}}>FAQ</MenuItemStyle_inner>
                            </CenterXS>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} >
                            <CenterXS>
                                <MenuItemStyle_Bold >Empresa</MenuItemStyle_Bold> 
                                <MenuItemStyle_inner onClick={()=>{navigate('/terms-and-conditions'); window.scrollTo(0, 0);}} >Termos e Condições</MenuItemStyle_inner>
                                <MenuItemStyle_inner onClick={()=>{navigate('/privacy'); window.scrollTo(0, 0);}} >Privacidade</MenuItemStyle_inner>
                            </CenterXS> 
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} >
                            <CenterXS>
                                <MenuItemStyle_Bold >Fale conosco</MenuItemStyle_Bold>
                                <MenuItemStyle_inner onClick={()=>{navigate('/contact-us'); window.scrollTo(0, 0);}} >Contato</MenuItemStyle_inner>
                            </CenterXS>
                        </Grid>
                    </Grid>
                </InnerContainerStyle>
            </ContainerStyle>
            <ContainerStyle>
                <FooterTextWrapper>
                    <MenuItemStyle_end>© {currentYear} BUSINESSNAME LTDA - CNPJ xx.xxx.xxx/xxxx-xx. Todos os direitos reservados</MenuItemStyle_end>
                </FooterTextWrapper>
            </ContainerStyle>
            <BoxWrapper>
                {/* triangle */}
                <Box sx={{
                    width: `${width * 0.266 > 383 ? 383 : width * 0.266}` + 'px',
                    height: `${width * 0.266 > 383 ? 383 : width * 0.266}` + 'px',
                    borderBottom: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' #2C2C2C',
                    borderLeft: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' #2C2C2C',
                    borderRight: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' transparent',
                    borderTop: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.266 > 383 ? 383 : width * 0.266}` + 'px',
                    height: `${width * 0.266 > 383 ? 383 : width * 0.266}` + 'px',
                    borderBottom: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' #2C2C2C',
                    borderLeft: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' transparent',
                    borderRight: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' #2C2C2C',
                    borderTop: 'solid ' + `${width * 0.133 > 191 ? 191 : width * 0.133}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </BoxWrapper>
        </RootStyle>
    )
}