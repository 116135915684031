import React, { useState, useEffect } from 'react';


import { styled } from '@mui/material';


import schedule from '../../assets/icons/schedule.svg'
import scheduleWhite from '../../assets/icons/scheduleWhite.svg'
import diamond from '../../assets/icons/diamondGold.svg'



const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundColor:'#fff',
    display:`flex`,
    flexDirection:`column`,
    alignItems:`center`,
    justifyContent:`center`,
    padding:`20px`,
    color:`#fff`,
    // height:'100vh',
    // overflow:'auto'
}));

const FullCardDesktop = styled('div')(({ theme }) => ({
    width: '80%',
    // backgroundColor:'red',
    // height:'80vh',
    display:`flex`,
    justifyContent:`center`,
    alignItems:"start",
    gap:`20px`,

    // overflow:'auto'


    [theme.breakpoints.down('md')]: {
        flexDirection:'column',
        width:'100%',

    }



}));

const LeftColumnDEsktop = styled('div')(({ theme }) => ({
    width: '20%',
    padding:`20px 0px 0px 0px`,
    // backgroundColor:'purple',
    // height:'100%',
    display:`flex`,
    borderRadius:`10px`, 
    overflow:`hidden`,

    [theme.breakpoints.down('md')]: {
        width:'100%',
    }



    // overflow:'auto'
}));
const AvatarBlockDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    // backgroundColor:'purple',
    // height:'100%',
    display:`flex`,
    flexDirection:`column`,
    justifyContent:`start`,
    alignItems:`center`,


    // overflow:'auto'
}));
const AvatarButtonBlockDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    // backgroundColor:'purple',
    // height:'100px',
    display:`flex`,
    flexDirection:`column`,
    justifyContent:`start`,
    alignItems:`center`,


    // overflow:'auto'
}));
const AvatarSingleButtonDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    // backgroundColor:'rgba(34, 34, 34, 0.8)',
    backgroundColor:'#fff',
    color:`#000`,
    height:'50px',
    display:`flex`,
    // flexDirection:`column`,
    justifyContent:`center`,
    alignItems:`center`,

    border:`1px solid #ccc`,
    borderLeft:`5px solid #ccc`,
    cursor:`pointer`,
    gap:`10px`,

    
    '&:hover': {
        borderLeftColor:`#000`,
        fontWeight:`bold`,
        // border:`5px solid red`,
        // background: "#f00",
     },
 

    // overflow:'auto'
}));
const RightColumnDEsktop = styled('div')(({ theme }) => ({
    width: '80%',
    // backgroundColor:'rgba(34, 34, 34, 0.8)',
    // height:'100%',
    display:`flex`,
    flexDirection:`column`,
    borderRadius:'10px',
    overflow:`hidden`,
    gap:'10px',
    color:`#000`,

    [theme.breakpoints.down('md')]: {
        width:'100%'
    }


    // overflow:'auto'
}));
const AboutMeDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundColor:'rgba(34, 34, 34, 0.8)',
    minHeight:'200px',
    maxHeight:'200px',
    height:'auto',
    display:`flex`,
    fontSize:`14pt`,
    fontWeight:`bold`,
    padding:`10px`,
    overflowY:`auto`,
    color:`#fff`,
    

    [theme.breakpoints.down('md')]: {
        width:'100%',
        maxHeight:'400px',
        // flexDirection:'row',

    }


    // overflow:'auto'
}));
const CompanyInfoDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    // backgroundColor:'rgba(34, 34, 34, 0.8)',
    // maxHeight:'300px',
    display:`flex`,
    // flexDirection:'column',
    flexWrap:`wrap`,
    // justifyContent:`left`,
    // alignItems:'start',
    // fontSize:`14pt`,
    // fontWeight:`bold`,
    // padding:`10px`,
    overflowY:`auto`,
    borderBottom:`0.2px solid #CCC`,
    gap:'10px', 

    // [theme.breakpoints.up('md')]: {
        // flexWrap:`wrap`,
        // flexDirection:'row',

    // }



    // overflow:'auto'
}));
const SingleCompanyInfoDesktop = styled('div')(({ theme }) => ({
    width: '30%',
    // backgroundColor:`red`,
    // backgroundColor:'rgba(34, 34, 34, 0.8)',
    // height:'200px',
    display:`flex`,
    padding:'10px',
    gap:'10px',
    // justifyContent:'space-between',
    // borderRight:'1px solid #CCC',
    // margin:'auto',
    // fontSize:`14pt`,
    // fontWeight:`bold`,
    // padding:`10px`,
    // overflowY:`auto`,
    
    [theme.breakpoints.down('md')]: {
        width:'100%',
        // flexDirection:'row',

    }



    // overflow:'auto'
}));
const SingleHighlightDesktop = styled('div')(({ theme }) => ({
    width: '30%',
    // backgroundColor:`red`,
    backgroundColor:'rgba(34, 34, 34, 0.8)',
    borderRadius:'20px'
,    // height:'200px',
    display:`flex`,
    flexDirection:'column',
    padding:'10px',
    // gap:'10px',
    justifyContent:'center',
    alignItems:'center',
    // borderRight:'1px solid #CCC',
    // margin:'auto',
    // fontSize:`14pt`,
    // fontWeight:`bold`,
    // padding:`10px`,
    // overflowY:`auto`,
    
    [theme.breakpoints.down('md')]: {
        width:'100%',
        // flexDirection:'row',

    }



    // overflow:'auto'
}));




export default function (){


    const [mainColor, setMainColor] = useState(`rgba(245, 40, 145, 0.8)`)
    const [isPremium, setIsPremium] = useState(true)



    return(
    
        <RootStyle>

            <FullCardDesktop>

                <LeftColumnDEsktop style={{backgroundColor:mainColor}}>

                    <AvatarBlockDesktop>
                        <div style={{width:`150px`, height:`150px`, borderRadius:`99px`, backgroundColor:`#CCC`, display:`flex`, justifyContent:`center`, alignItems:`center`}}>
                            <img src='https://bootdey.com/img/Content/avatar/avatar3.png' width={`130px` } height={`130px`} style={{borderRadius:`99px`}}/>
                        </div>
                        <h3 style={{}}>Company name</h3>
                        <h6 style={{fontWeight:`normal`, marginTop:`-20px`, fontSize:`12pt`}}>company@email.com</h6>
                        <h6 style={{fontWeight:`normal`, marginTop:`-20px`, fontSize:`12pt`}}>+55 (99) 99999-9999</h6>

                        <AvatarButtonBlockDesktop>
                            <AvatarSingleButtonDesktop> <img src={schedule} width={`20px`} height={'20px'} alt='schedule here' /> Agendar agora</AvatarSingleButtonDesktop>
                            <AvatarSingleButtonDesktop> <img src={schedule} width={`20px`} height={'20px'} alt='schedule here' /> Agendar agora</AvatarSingleButtonDesktop>
                            <AvatarSingleButtonDesktop> <img src={schedule} width={`20px`} height={'20px'} alt='schedule here' /> Agendar agora</AvatarSingleButtonDesktop>


                        </AvatarButtonBlockDesktop>



                    </AvatarBlockDesktop>


                </LeftColumnDEsktop>




                <RightColumnDEsktop>
                    <AboutMeDesktop style={{backgroundColor:mainColor}}>huihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbvhuihwiefhueh fuhewufieiufe dfc erhgvjerfvruebcfn werufenufiewbuifgwe rjbcjsdb vjcbdshvbhbvhe brvbhedfbvhbfdhvb herbghvbfdhgbr hverbhgvjbdfhvbhrv dfgbhvjfdbv</AboutMeDesktop>

                    <CompanyInfoDesktop>
                        <SingleCompanyInfoDesktop style={{width:`100%`, fontSize:'14pt', fontWeight:'bold', }}>Dados</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Rua:</p> Jose dos Pinheiros Alencar</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Número:</p> 5765</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Bairro:</p> Portal Leste</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Cidade:</p> Campina Grande</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Estado:</p> Paraíba</SingleCompanyInfoDesktop>
                        <SingleCompanyInfoDesktop> <p style={{fontWeight:`bold`, fontSize:`12pt`, margin:'0px', }}>Pais:</p> Brasil</SingleCompanyInfoDesktop>

                    </CompanyInfoDesktop>
                    <CompanyInfoDesktop>
                        <SingleCompanyInfoDesktop style={{width:`100%`, fontSize:'14pt', fontWeight:'bold', }}>Destaques</SingleCompanyInfoDesktop>

                        {isPremium && 
                        
                            <SingleHighlightDesktop style={{backgroundColor:mainColor}}>
                                <img src={diamond} width={'100px'} height={'100px'} alt='premium' />
                                <h3>Membro Premium</h3>
                                <h6 style={{marginTop:'-20px', fontWeight:'normal', }}>Mais confiança para você</h6>
                            </SingleHighlightDesktop>
                        }
                            <SingleHighlightDesktop style={{backgroundColor:mainColor}}>
                                <img src={diamond} width={'100px'} height={'100px'} alt='premium' />
                                <h3>Membro Premium</h3>
                                <h6 style={{marginTop:'-20px', fontWeight:'normal', }}>Mais confiança para você</h6>
                            </SingleHighlightDesktop>

                            <SingleHighlightDesktop style={{backgroundColor:mainColor}}>
                                <img src={diamond} width={'100px'} height={'100px'} alt='premium' />
                                <h3>Membro Premium</h3>
                                <h6 style={{marginTop:'-20px', fontWeight:'normal', }}>Mais confiança para você</h6>
                            </SingleHighlightDesktop>

                            <SingleHighlightDesktop style={{backgroundColor:mainColor}}>
                                <img src={diamond} width={'100px'} height={'100px'} alt='premium' />
                                <h3>Membro Premium</h3>
                                <h6 style={{marginTop:'-20px', fontWeight:'normal', }}>Mais confiança para você</h6>
                            </SingleHighlightDesktop>


                    </CompanyInfoDesktop>


                </RightColumnDEsktop>

            </FullCardDesktop>


        </RootStyle>





    )
}