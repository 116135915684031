import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';

import useWindowSize from '../../hooks/useWindowSize';

import logo from '../../logo.svg'
import home from '../../assets/icons/home.svg'
import account from '../../assets/icons/account.svg'
import plan from '../../assets/icons/plan.svg'
import arrowDown_purple from '../../assets/icons/arrowDown_purple.svg'
import arrowDown_white from '../../assets/icons/arrowDown_white.svg'

const RootDesktop = styled('div')({
    width:'100%',
    zIndex:'999999999999999',
    display:'flex',
    maxWidth:'100vw',
    height:'80px',
    backgroundColor:'#8327f5',
    justifyContent:'center',
    alignItems:'center',
    gap:'20px', 
    padding:'2px',

})
const ElementDesktop = styled('a')({
    display:'flex',
    width:'100px',
    color:'#fff',
    cursor:'pointer',
    textAlign:'center',
    justifyContent:'center',
    fontSize:'14pt',
    borderRadius:'5px',
    padding:'10px',
    fontWeight:'bold',
    textDecorationLine:'none',
    zIndex:'999',

    '&:hover':{
        backgroundColor:'#fff',
        color:'#8327f5',
    }

})
const SubMenuHoverDesktop = styled('a')({
    display:'flex',
    minWidth:'50px',
    maxWidth:'300px',
    minHeight:'80px',
    zIndex:'999',
    color:'#fff',
    backgroundColor:'#8327f5',

    position:'fixed',
    top:'62px',
    // left:'0',
    margin:'auto',
    // padding:'10px 10% 10px 10%',
    padding:'10px',
    gap:'20px',
    flexWrap:'wrap',
    justifyContent:'center',
    alignItems:'center',

    transformOrigin: '50% -30px',



})

const RootMobile = styled('div')({
    position:'fixed',
    bottom:'0',
    display:'flex',
    minWidth:'100vw',
    maxHeight:'50px',
    backgroundColor:'#8327f5',
    justifyContent:'center',
    alignItems:'end',
    gap:'20px', 
    padding:'0px',
    zIndex:'9999999',

})
const ElementMobile = styled('a')({
    display:'flex',
    flexDirection:'column',
    gap:'0px',
    width:'20%',
    color:'#fff',
    cursor:'pointer',
    alignItems:'center',
    fontSize:'8pt',
    margin:'auto 0px 0px 0px',

    borderRadius:'5px',
    padding:'10px',
    // fontWeight:'bold',
    textDecorationLine:'none',


})

function Header() {
    const {width} = useWindowSize()

    const [isHovering , setIsHovering] = useState('')


    return ( 
        <>
        {width>700?
        <RootDesktop>
            <ElementDesktop href='/' >Home</ElementDesktop>
            <ElementDesktop href='/plans' >Planos</ElementDesktop>
            <ElementDesktop href='/' style={{margin:'auto 50px auto 50px', padding:'2px'}}><img src={logo} width='100px' /></ElementDesktop>
            <ElementDesktop href='/advantages' >Vantagens</ElementDesktop>
            <ElementDesktop href='/partners' onMouseEnter={()=>{setIsHovering('links')}} onMouseLeave={()=>{setIsHovering('')}} >Links< img  src={isHovering==='links'?arrowDown_purple:arrowDown_white} width={'20px'} style={{margin:'0px 0px 0px 10px'}} /> 
                {isHovering && 

                <>
                    <SubMenuHoverDesktop  onMouseEnter={()=>{setIsHovering('links')}}  >
                        <ElementDesktop href='/profile/567567567'               style={{width:'100%'}}>Profile</ElementDesktop>
                        <ElementDesktop href='/schedule/674576457'              style={{width:'100%'}}>Schedule</ElementDesktop>
                        <ElementDesktop href='/account'                           style={{width:'100%'}}>Panel</ElementDesktop>
                        <ElementDesktop href='/feedback/76567657'               style={{width:'100%'}}>Feedback</ElementDesktop>
                        <ElementDesktop href='/terms-of-service'                style={{width:'100%'}}>Terms of service</ElementDesktop>
                        <ElementDesktop href='/privacy'                         style={{width:'100%'}}>Privacy</ElementDesktop>
                    </SubMenuHoverDesktop>
                </>
                }


            </ElementDesktop>


            <ElementDesktop href='/login' style={{position:'absolute', right:'30px', backgroundColor:'#00C2AC', color:'', }} >Entrar</ElementDesktop>




        </RootDesktop>
        :
        <RootMobile>
            <ElementMobile><img style={{margin:'auto 0px 0px 0px'}} src={home} width='70%' /><p style={{margin:'0px 0px 0px 0px'}}>Home</p></ElementMobile>
            <ElementMobile><img style={{margin:'auto 0px 0px 0px'}} src={home} width='70%' /><p style={{margin:'0px 0px 0px 0px'}}>Home</p></ElementMobile>
            <ElementMobile style={{backgroundColor:'#8327f5', borderRadius:'100px', width:'40%', margin:'0px 0px -10px 0px'}}><img style={{margin:'auto 0px 0px 0px'}} src={home} width='70%' /><p style={{margin:'0px 0px 0px 0px'}}>Home</p></ElementMobile>
            <ElementMobile><img style={{margin:'auto 0px 0px 0px'}} src={plan} width='70%' /><p style={{margin:'0px 0px 0px 0px'}}>Home</p></ElementMobile>
            <ElementMobile><img style={{margin:'auto 0px 0px 0px'}} src={account} width='70%' /><p style={{margin:'0px 0px 0px 0px'}}>Home</p></ElementMobile>

        </RootMobile>
        }
        </>
     );
}

export default Header;