import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material';

const CustomButtom = styled('button')(({theme})=>({
    minWidth:'250px',
    height:'45px',
    fontSize:'14pt', 
    padding:'10px',
    cursor:'pointer',
    color:'#fff',
    borderRadius:'5px',
    border:'none',
    margin:'auto 0px auto 0px',
    backgroundColor:'#000'
    // fontWeight:'bold',
}))

export default function ({text, customFunction, style }){

    return (
        <CustomButtom style={style} onClick={customFunction}>{text || ''}</CustomButtom>
        // <CustomButtom >gtrghtr hrtyehgr</CustomButtom>
    )
}