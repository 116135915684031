import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
  
} from "react-router-dom";
import { styled } from '@mui/material/styles';

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/NotFound/NotFound";
import Profile from "./pages/Profile/Profile";
import Account from "./pages/Account/Account";

import './App.css'


const RootStyle = styled('div')(({ theme }) => ({
  width: '100vw',
  height:'100vh',
  // overflowY:'auto',
  // overflowX:'hidden',
  // display:'flex',
  // flexDirection:'column',

}));
const BodyContent = styled('div')(({ theme }) => ({
  width: '100vw',
  height:'calc(100vh - 80px)',
  overflowY:'auto',
  overflowX:'hidden',
  zIndex:'9',
  // display:'flex',
  // flexDirection:'column',

}));


const router = createBrowserRouter(
  [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/signup",
        element: <Login />,
    },
    {
        path: "/profile/*",
        element: <Profile />,
    },
    {
        path: "/account/*",
        element: <Account />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
  ]
)


function App() {
  return (
    
      <RootStyle>
        <Header />
          <BodyContent>
            <RouterProvider router={router} />
            <Footer />
          </BodyContent>
      </RootStyle>
    );
}

export default App;
