import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import CustomButton from '../Button/CustomButton';

const Root = styled('div')(({theme})=>({
    width:'100%',
    display:'flex',
    flexDirection:'column',
    padding:'0px 20px 20px 20px',
    gap:'10px',
    // zIndex:'99999'

}))
const SubHeader = styled('h1')(({theme})=>({
    width:'100%',
    display:'flex',
    gap:'20px',
    justifyContent:'center',
    fontSize:'16pt !important',
    borderBottom:'0.5px solid #CCC',
    padding:'0px 0px 10px 0px'



}))

const UserPersonalData = styled('div')(({theme})=>({
    width:'60%',
    // minHeight:'10px',
    // maxHeight:'100vh',
    // height:'100vh',
    display:'flex',
    justifyContent:'space-around',
    flexWrap:'wrap',
    gap:'20px',
    // backgroundColor:'yellow',


}))
const SinglePersonalData = styled('div')(({theme})=>({
    minWidth:'300px',
    minHeight:'50px',
    display:'flex',
    flexDirection:'column',
    // flexWrap:'wrap',
    gap:'0px',
    // backgroundColor:'red',
    // border:'0.2px solid rgba(0, 0, 0, 0.1)',
    // borderRadius:'10px',



}))
const ElementLabel = styled('h3')(({theme})=>({
    width:'100%',
    display:'flex',
    fontSize:'13pt !important',
    margin:'0px 0px 10px 0px',
    // borderBottom:'0.5px solid #CCC',
    // padding:'0px 0px 10px 0px'



}))
const OptionLabel = styled('h3')(({theme})=>({
    fontSize:'14pt', 
    // borderTop:'0.2px solid #CCC', 
    // borderLeft:'0.2px solid #CCC', 
    // borderBottom:'5px solid #CCC', 
    // borderRight:'5px solid #CCC', 
    borderRadius:'9px', 
    padding:'10px',
    cursor:'pointer',
    color:'#000',

    // background: 'rgb(255,255,255)',
    // background: 'linear-gradient(138deg, rgba(0,0,0, 0.3) 65%, rgba(0,0,0,0.1) 100%)',






}))
// const CustomButtom = styled('button')(({theme})=>({
//     minWidth:'250px',
//     height:'45px',
//     fontSize:'14pt', 
//     padding:'10px',
//     cursor:'pointer',
//     color:'#fff',
//     borderRadius:'5px',
//     border:'none',
//     margin:'auto 0px auto 0px',
//     // fontWeight:'bold',
// }))



export default function (){

    const [option, setOption]=useState(1)
    const [currentUser, setCurrentUser]=useState(
        {
            userType:1
        }
    )

    return (

        <Root>

            <SubHeader>
                <OptionLabel style={{ 
                    fontWeight:option===0?'':'normal', 
                    // fontSize:option===0?'18pt':'', 
                    color: option===0?'#fff':'',
                    background: option===0?'rgb(0,194,172)':'',
                    background: option===0?'linear-gradient(138deg, rgba(131, 39, 245, 1) 65%, rgba(131, 39, 245, 0.2) 100%)':'',
                
            
            }} onClick={()=>{setOption(0)}} >Informações Pessoais</OptionLabel>    
                <OptionLabel style={{ 
                    fontWeight:option===1?'':'normal', 
                    // fontSize:option===1?'18pt':'', 
                    color: option===1?'#fff':'',
                    background: option===1?'rgb(0,194,172)':'',
                    background: option===1?'linear-gradient(138deg, rgba(131, 39, 245, 1) 65%, rgba(0,0,0,0.15) 100%)':'',
                
            
            }} onClick={()=>{setOption(1)}} >Endereço</OptionLabel>    
            </SubHeader>



{option?

                        <UserPersonalData style={{width:'100%'}}>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="CEP" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="Rua" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="Número" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="Bairro" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="Cidade" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="Estado" variant="outlined" fullWidth  />
                            </SinglePersonalData>
                            <SinglePersonalData style={{width:'400px'}}>
                                {/* <ElementLabel>Nome completo:</ElementLabel> */}
                            <TextField id="outlined-basic" label="País" variant="outlined" fullWidth  />
                            </SinglePersonalData>



                            <div style={{width:'100%', display:'flex', gap:'50px', justifyContent:'center', margin:'20px 0px 20px 0px'}}>
                                <CustomButton  style={{backgroundColor:'rgba(0, 211, 82, 1)' , fontWeight:'bold', }}  text='Salvar' customFunction={()=>{alert('salvar')}} />
                                <CustomButton  style={{backgroundColor:'rgba(0, 0, 0, 0.2)', color:'#000', }}  text='Cancelar' customFunction={()=>{alert('Cancelar')}} />
                            </div>
                        </UserPersonalData>



:



            <div style={{
                width:'100%',
                // minHeight:'500px',
                display:'flex',
                gap:'20px',

                
                
            }}>
            {/* {currentUser?.userType===1? */}
            <div style={{
                width:'40%',
                minHeight:'400px', 
                backgroundColor:'red',

            }}>

            </div>

                <UserPersonalData>




                    <SinglePersonalData>
                        {/* <ElementLabel>Nome completo:</ElementLabel> */}
                      <TextField id="outlined-basic" label="Nome completo" variant="outlined" fullWidth  />
                    </SinglePersonalData>
                    <SinglePersonalData>
                        {/* <ElementLabel>Nome completo:</ElementLabel> */}
                      <TextField id="outlined-basic" label="Nome completo" variant="outlined" fullWidth  />
                    </SinglePersonalData>
                    <SinglePersonalData>
                        {/* <ElementLabel>Nome completo:</ElementLabel> */}
                      <TextField id="outlined-basic" label="Nome completo" variant="outlined" fullWidth  />
                    </SinglePersonalData>
                    <SinglePersonalData>
                        {/* <ElementLabel>Nome completo:</ElementLabel> */}
                      <TextField id="outlined-basic" label="Nome completo" variant="outlined" fullWidth  />
                    </SinglePersonalData>
                    <SinglePersonalData>
                        {/* <ElementLabel>Nome completo:</ElementLabel> */}
                      <TextField id="outlined-basic" label="Nome completo" variant="outlined" fullWidth  />
                    </SinglePersonalData>




                </UserPersonalData>
            
            {/* : */}
            {/* <></> */}
        
            {/* } */}

            </div>


        }


        </Root>

    )
}