import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material';

import PersonalInfo from '../../components/PersonalInfo/PersonalInfo';


import accountWhite from '../../assets/icons/accountWhite.svg'
import arrowRightWhite from '../../assets/icons/arrowRightWhite.svg'
import arrowLeftWhite from '../../assets/icons/arrowLeftWhite.svg'
import aboutMeWhite from '../../assets/icons/about-svgrepo-com.svg'
import passwordWhite from '../../assets/icons/password-svgrepo-com.svg'
import notificationsWhite from '../../assets/icons/notifications-alert-svgrepo-com.svg'
import plan from '../../assets/icons/starFilled.svg'
import securityWhite from '../../assets/icons/security-safe-svgrepo-com.svg'
import accountWhite1 from '../../assets/icons/account-settings-svgrepo-com.svg'


const Root = styled('div')(({theme})=>({
    width:'100%',
    display:'flex',
    

    // flexDirection:'column',
    

}))
const LeftColumn = styled('div')(({theme})=>({
    width:'20%',
    display:'flex',
    flexDirection:'column',

    minHeight:'200px',
    // height:'100%',
    backgroundColor:'#8327f5',
    gap:'10px',
    padding:'10px',
    boxShadow:'1px 0px 15px 5px rgba(0,0,0,0.5)',
    zIndex:'99'




}))
const LeftColumnItem = styled('div')(({theme})=>({
    width:'90%',
    height:'50px',
    display:'flex',
    gap:'10px',
    color:'#fff',
    borderRadius:'10px',
    // backgroundColor:'rgba(255, 255, 255, 0.15)',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(138deg, rgba(255,255,255,0.2) 55%, rgba(0,0,0,0.2) 100%)',

    margin:'0px auto 0px auto',
    justifyContent:'left',
    alignItems:'center',
    padding:'10px',
    fontSize:'14pt',
    cursor:'pointer',





}))

const AvatarBlockDesktop = styled('div')(({ theme }) => ({
    width: '100%',
    display:`flex`,
    flexDirection:`column`,
    justifyContent:`start`,
    alignItems:`center`,
    color:'#fff',
    margin:'10px 0px 30px 0px',
    
}));




const RightColumn = styled('div')(({theme})=>({
    width:'80%',
    display:'flex',
    flexDirection:'column',

    // minHeight:'200px',
    // height:'100vh',
    backgroundColor:'#fff',
    gap:'10px',
    padding:'10px',




}))


export default function (){

    const [option,setOption] = useState(0)
    const [openMenu,setOpenMenu] = useState(true)




    return (

        <Root>

            <LeftColumn style={{width:openMenu?'20%':'5%'}}>

                <div style={{width:'100%', display:'flex', justifyContent:openMenu?'end':'center'}}>
                    <img src={openMenu? arrowLeftWhite : arrowRightWhite} width={'30px'} height={'30px'} alt=''  style={{cursor:'pointer'}} onClick={()=>{setOpenMenu(!openMenu)}} />
                </div>


                <AvatarBlockDesktop >
                    <div style={{width:openMenu?`150px`:'70px', height:openMenu?`150px`:'70px', borderRadius:`99px`, backgroundColor:`#CCC`, display:`flex`, justifyContent:`center`, alignItems:`center`}}>
                        <img src='https://bootdey.com/img/Content/avatar/avatar3.png' width={openMenu?`130px`:'60px' } height={openMenu?`130px`:'60px'} style={{borderRadius:`99px`}}/>
                    </div>
                    {openMenu && <h3 style={{fontSize:openMenu?'18pt':'10pt', }}>User/Company name</h3> }
                </AvatarBlockDesktop>


                <LeftColumnItem onClick={()=>{setOption(0)}}  style={{
                    background: option===0?'rgb(0,194,172)':'',
                    background: option===0?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={accountWhite} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Informações pessoais' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(1)}}  style={{
                    background: option===1?'rgb(0,194,172)':'',
                    background: option===1?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={aboutMeWhite} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Sobre mim' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(2)}}  style={{
                    background: option===2?'rgb(0,194,172)':'',
                    background: option===2?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={passwordWhite} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Senha' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(3)}}  style={{
                    background: option===3?'rgb(0,194,172)':'',
                    background: option===3?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={notificationsWhite} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Notificações e alertas' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(4)}}  style={{
                    background: option===4?'rgb(0,194,172)':'',
                    background: option===4?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={plan} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Planos' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(5)}}  style={{
                    background: option===5?'rgb(0,194,172)':'',
                    background: option===5?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={securityWhite} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Segurança' : '' }
                </LeftColumnItem>
                <LeftColumnItem onClick={()=>{setOption(6)}}  style={{
                    background: option===6?'rgb(0,194,172)':'',
                    background: option===6?'linear-gradient(138deg, rgba(0,194,172,1) 55%, rgba(0,0,0,0.15) 100%)':'',
                    justifyContent:openMenu?'':'center'

                }} >
                    <img src={accountWhite1} width={'25px'} height={'25px'} alt='account' /> {openMenu? 'Conta' : '' }
                </LeftColumnItem>
                
                
            </LeftColumn>



            <RightColumn style={{width:openMenu?'80%':'95%'}}>

                {
                    option === 0 && <PersonalInfo />
                }

            </RightColumn>


        </Root>

    )
}